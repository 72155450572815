import "firebase/database";
import {
  doc,
  getFirestore,
  setDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../fb";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const audio = new Audio("http://mediaserv38.live-streams.nl:8027/live");
const audio = new Audio(
  "https://fm1.cvdrbroadcastsolutions.com:8443/joyous_radio.aac"
);
export const RadioService = {
  getStreamData: async () => {
    const response = await fetch(audio);
    const stream = await response;

    console.log(stream);
  },
  play: async () => {
    await audio.play();
  },
  pause: async () => {
    await audio.pause();
  },
  sendMessage: (data) => {
    return new Promise(async (resolve, reject) => {
      const date = new Date();
      const message = { ...data, sent: date.toDateString() };
      console.log('sending message');
      await addDoc(collection(db, "messages"), message)
        .then((res) => {
          resolve(true);
        })
        .catch((err) => {
          reject(false);
        });
    });
  },
};
