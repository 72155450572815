import React, { useState } from "react";
import Layout from "../components/layout";
import {
  CircularProgress,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { PauseCircle, PlayCircle } from "@mui/icons-material";
import { RadioService } from "../services/radio";
import { message } from "antd";

function Live() {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = async () => {
    setLoading(true);
    if (playing) {
      await RadioService.pause()
        .then((d) => {
          setLoading(false);
          // message.success("Enjoy and Gob bless you. 😇")
          setPlaying(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(`Ooops! Something went wrong. ${err.message}`);
        });
    } else {
      await RadioService.play()
        .then((d) => {
          setLoading(false);
          message.success("Enjoy and God bless you. 😇");
          setPlaying(true);
          RadioService.getStreamData().then((data) => {
            console.log(data);
          });
        })
        .catch((err) => {
          setLoading(false);
          message.error(`Ooops! Something went wrong. ${err.message}`);
        });
    }
  };
  return (
    <Layout>
      <Stack>
        <Container>
          <Stack spacing={5} py={10} textAlign={"center"}>
            <Stack pt={10}>
              <Typography variant="h6">
                <b>Live Radio</b>
              </Typography>
              <Typography variant="h2">
                <span>Joyous</span> Radio
              </Typography>
            </Stack>
            <Stack>
              {loading && (
                <IconButton sx={{ alignSelf: "center" }} size="large">
                  <CircularProgress
                    size={190}
                    sx={{ width: 200, height: 200 }}
                  />
                </IconButton>
              )}
              {!loading && (
                <IconButton
                  onClick={() => {
                    toggle();
                  }}
                  sx={{ alignSelf: "center" }}
                  size="large"
                >
                  {loading === false && playing ? (
                    <PauseCircle sx={{ width: 200, height: 200 }} />
                  ) : (
                    <PlayCircle sx={{ width: 200, height: 200 }} />
                  )}
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Layout>
  );
}

export default Live;
